<template>
    <div class="Impersonate">

        <div class="Impersonate__title">
            Login
        </div>

        <div
            v-if="!isTokenInvalid"
            class="Impersonate__lead">
            Please wait
        </div>

        <Alert
            v-if="isTokenInvalid"
            addclass="mt-20"
            message="Invalid token"
        ></Alert>

        <button
            @click.prevent="$router.push('/'+$route.params.lng+'/auth/login')"
            class="wv-btn--green wv-btn--block mt-20">
            Login
        </button>

    </div>
</template>

<script>
export default {
    name: 'Impersonate',
    props: [],
    data() {
        return {
            isTokenInvalid: false,
        };
    },
    computed: {
        impersonateToken() {
            return this.$route.params.token;
        },
    },
    methods: {
        requestImpersonate() {
            return new Promise((resolve, reject) => {
                WV.Api.postImpersonate(this.impersonateToken)
                    .then(response => {
                        this.$store.commit('auth/setToken', response.token);
                        this.$store.commit('auth/setRole', 'webmaster');
                        localStorage.setItem('token', response.token);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },
    beforeMount() {
        this.$store.commit('auth/setToken', false);
        localStorage.removeItem('token');
    },
    mounted() {
        this.requestImpersonate()
            .then(() => {
                this.$router.push('/' + this.$route.params.lng + '/webmaster/dashboard');
            })
            .catch(() => {
                this.isTokenInvalid = true
            });
    },
};
</script>

<style lang="scss">

@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.Impersonate {
    width: 100%;
    max-width: 320px;
    &__title {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        padding-bottom: 15px;
    }
    &__lead {
        text-align: center;
        font-family: $font-secondary;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
    
}
</style>