<template>
    <div class="AuthManager">

        <div class="AuthManager__title">
            For login, go to link
        </div>

        <div class="AuthManager__lead">
            <a
                href="https://webvork.com/en/">
                webvork.com
            </a>
        </div>



    </div>
</template>

<script>
export default {
    name: 'AuthManager',
    props: [],
    data() {
        return {};
    },
    computed: {},
    methods: {},
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';
.AuthManager {
    max-width: 320px;
    &__title {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        padding-bottom: 15px;
    }
    &__lead {
        text-align: center;
        font-family: $font-secondary;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        a {
            color: $color-day-light-green;
        }
    }

}
</style>