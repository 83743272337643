<template>
    <div class="Login">

        <div class="Login__title">
            {{ $lng.__('lk_webmaster_main', 'Welcome back to Webvōrk') }}
        </div>

        <div class="Login__lead">
            {{ $lng.__('lk_webmaster_main', 'New user?') }}
            <router-link :to="`/${this.$route.params.lng}/auth/register${this.getSearchParams}`">{{ $lng.__('lk_webmaster_main', 'Create an account') }}</router-link>
        </div>

        <div class="Login__form">

            <FormInput
                class="mb-20"
                :label="false"
                @keyup.enter="clickLogin()"
                :placeholder="$lng.__('lk_webmaster_main', 'Login or Email')"
                v-model.trim="form.login"
                :validations="[
                    {
                        rule: $validations.isStringNotEmpty(form.login),
                        message: $lng.__('lk_webmaster_main', `Enter email`)
                    }
                ]"
            ></FormInput>

            <FormInput
                type="password"
                class="mb-20"
                :label="false"
                @keyup.enter="clickLogin()"
                :placeholder="$lng.__('lk_webmaster_main', 'Password')"
                v-model="form.password"
                :validations="[
                    {
                        rule: $validations.isStringNotEmpty(form.login),
                        message: $lng.__('lk_webmaster_main', `Enter password`)
                    },
                    {
                        rule: $validations.isStringLengthSmallThan(form.password, 64),
                        message: $lng.__('lk_webmaster_main', `Password must be less than 64 symbols`)
                    }
                ]"
            ></FormInput>

            <Alert
                v-if="isLoginFailed"
                addclass="mb-20"
                :message="$lng.__('lk_webmaster_main', 'Wrong login or password. Please check credentials and login again')"
            ></Alert>

            <button
                @keyup.enter="clickLogin()"
                @click="clickLogin()"
                class="wv-btn--green wv-btn--block">
                {{ $lng.__('lk_webmaster_main', 'Login') }}
            </button>

            <button
                @click.prevent="$router.push(`/${$route.params.lng}/auth/recovery${this.getSearchParams}`)"
                class="wv-btn--transparent wv-btn--block mt-20">
                {{ $lng.__('lk_webmaster_main', 'Restore password') }}
            </button>

        </div>

    </div>
</template>

<script>
export default {
    name: 'Login',
    props: [],
    data() {
        return {
            form: {
                login: '',
                password: '',
            },
            isLoginFailed: false,
        };
    },
    computed: {
        isFormDataValid() {
            return this.$validations.isStringNotEmpty(this.form.login)
                && this.$validations.isStringNotEmpty(this.form.password)
                && this.$validations.isStringLengthSmallThan(this.form.password, 64);
        },
        getSearchParams(){
            return WV.getUrlSearchParams();
        }
    },
    methods: {
        clickLogin() {
            WV.Bus.emit('fireValidations');
            this.isLoginFailed = false;

            if (this.isFormDataValid) {
                this.$store.commit('auth/setLoginAndPassword', {
                    login: this.form.login,
                    password: this.form.password,
                });
                this.$store.dispatch('auth/login')
                    .then(() => {
                        if (this.$store.getters['auth/isRoleWebmaster']) {
                            WV.toast.success('Successfully authorized');
                            this.$router.push('/' + this.$route.params.lng + '/webmaster/dashboard');
                        } else if (this.$store.getters['auth/isRoleManager']) {
                            localStorage.removeItem('token');
                            if (WV.domainZone === 'ch') {
                                document.location.href = 'https://webvork.ch/en/user/login';
                            } else {
                                document.location.href = WV.domainZone === 'ru'
                                    ? 'https://webvork.ru/en/user/login'
                                    : 'https://webvork.com/en/user/login';
                            }
                        } else if (this.$store.getters['auth/isRoleOperator']) {
                            localStorage.removeItem('token');
                            if (WV.domainZone === 'ch') {
                                document.location.href = 'https://bestcallcentre.webvork.ch/en/user/login';
                            } 
                                document.location.href = WV.domainZone === 'ru'
                                    ? 'https://bestcallcentre.webvork.ru/en/user/login'
                                    : 'https://bestcallcentre.info/en/user/login';
                            }
                        }
                    )
                    .catch(() => {
                        this.isLoginFailed = true;
                    });
            }
        }
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';
.Login {
    max-width: 320px;
    &__title {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        padding-bottom: 15px;
    }
    &__lead {
        text-align: center;
        font-family: $font-secondary;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        a {
            color: $color-day-light-green;
        }
    }
    &__form {
        padding-top: 50px;
    }
}
</style>
