<template>
    <div class="Restore">

        <div class="Restore__title">
            {{ $lng.__('lk_webmaster_main', 'Restore password') }}
        </div>


        <template v-if="isEmailSended">
            <div class="Restore__lead">
                {{ $lng.__('lk_webmaster_main', 'We sent an email with a link to change your password to') + form.email }}
                <br>
                {{ $lng.__('lk_webmaster_main', '(make sure it didn\'t end up in your spam folder)') }}
                <br>
                <br>
                {{ $lng.__('lk_webmaster_main', 'Please close this window and check your email') }}
            </div>
        </template>

        <template v-if="!isEmailSended">
            <div class="Restore__lead">
                {{ $lng.__('lk_webmaster_main', 'Remember your password?') }}
                <router-link :to="`/${this.$route.params.lng}/auth/login${this.getSearchParams}`">{{ $lng.__('lk_webmaster_main', 'Log in')}}</router-link>
            </div>

            <div class="Restore__form">

                <FormInput
                    class="mb-20"
                    :label="false"
                    :placeholder="$lng.__('lk_webmaster_main', 'Enter email')"
                    v-model="form.email"
                    :validations="[
                    {
                        rule: $validations.isEmailValid(form.email),
                        message: $lng.__('lk_webmaster_main', `Verify email`)
                    }
                ]"
                ></FormInput>

                <Alert
                    v-if="isRestoreFailed"
                    addclass="mb-20"
                    :message="$lng.__('lk_webmaster_main', 'Wrong login or password. Please check credentials and login again')"
                ></Alert>

                <button
                    @click="clickRestore()"
                    class="wv-btn--green wv-btn--block">
                    {{ $lng.__('lk_webmaster_main', 'Restore') }}
                </button>

            </div>
        </template>


    </div>
</template>

<script>
export default {
    name: 'Restore',
    props: [],
    data() {
        return {
            form: {
                email: '',
            },
            isRestoreFailed: false,
            isEmailSended: false,
            isSending: false
        }
    },
    computed: {
        isFormDataValid() {
            return this.$validations.isEmailValid(this.form.email)
        },
        getSearchParams(){
            return WV.getUrlSearchParams();
        }
    },
    methods: {
        clickRestore() {
            if(!this.isSending) {
                WV.Bus.emit('fireValidations')
                this.isRestoreFailed = false
                this.isSending = true

                if (this.isFormDataValid) {
                    this.$store.dispatch('auth/sendRecoveryPasswordEmail', this.form.email).then(() => {
                        WV.toast.success('Check your email')
                        this.isEmailSended = true
                        this.isSending = false
                    }).catch(() => {
                        this.isRestoreFailed = true
                        this.isSending = false
                    })
                }
            }
        }
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .Restore{
        max-width: 320px;
        &__title{
            font-family: $font-primary;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.005em;
            text-align: center;
            padding-bottom: 15px;
        }
        &__lead{
            text-align: center;
            font-family: $font-secondary;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.5px;
            a{
                color: $color-day-light-green;
            }
        }
        &__form{
            padding-top: 50px;
        }
    }
</style>