<template>
    <div class="Activation">

        <div class="Activation__title">
            {{ $lng.__('lk_webmaster_main', 'Account activation')}}
        </div>

        <div class="Activation__lead">
            {{ $lng.__('lk_webmaster_main', 'New user?') }}
            <a
                @click.prevent="$router.push('/'+$route.params.lng+'/auth/register')"
                href="#">
                {{ $lng.__('lk_webmaster_main', 'Create an account') }}
            </a>
        </div>

        <div class="Activation__form">

            <FormInput
                class="mb-20"
                :label="false"
                :placeholder="$lng.__('lk_webmaster_main', 'Email')"
                v-model="email"
                :validations="[
                    {
                        rule: $validations.isEmailValid(email),
                        message: $lng.__('lk_webmaster_main', `Verify email`)
                    }
                ]"
            ></FormInput>

            <button
                @click="clickActivation()"
                class="wv-btn--green wv-btn--block">
                {{ $lng.__('lk_webmaster_main', 'Send activation link')}}
            </button>

            <button
                @click.prevent="$router.push('/'+$route.params.lng+'/auth/restore')"
                class="wv-btn--transparent wv-btn--block mt-20">
                {{ $lng.__('lk_webmaster_main', 'Restore password') }}
            </button>

        </div>

    </div>
</template>

<script>
export default {
    name: 'Activation',
    props: [],
    data() {
        return {
            email: '',
        }
    },
    computed: {
        isFormDataValid() {
            /*return this.$validations.isStringNotEmpty(this.form.login)
                && this.$validations.isStringNotEmpty(this.form.password)
                && this.$validations.isStringLengthSmallThan(this.form.password, 64)*/
        },
    },
    methods: {
        clickActivation() {
            WV.Bus.emit('fireValidations')
            if (this.isFormDataValid) {
                // TODO api request
            }
        }
    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';
    .Activation{
        max-width: 320px;
        &__title{
            font-family: $font-primary;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.005em;
            text-align: center;
            padding-bottom: 15px;
        }
        &__lead{
            text-align: center;
            font-family: $font-secondary;
            font-size: 18px;
            line-height: 20px;
            letter-spacing: 0.5px;
            a{
                color: $color-day-light-green;
            }
        }
        &__form{
            padding-top: 50px;
        }
    }
</style>