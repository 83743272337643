<template>
    <div class="RecoveryFinal">

        <div class="RecoveryFinal__title">
            Set new password
        </div>


            <div class="RecoveryFinal__lead">
                Remember your password?
                <a
                    @click.prevent="$router.push('/'+$route.params.lng+'/auth/login')"
                    href="#">
                    Login
                </a>
            </div>

            <div class="RecoveryFinal__form">

                <FormInput
                    type="password"
                    class="mb-20"
                    :label="false"
                    placeholder="Password"
                    v-model="password"
                    :validations="[
                    {
                        rule: $validations.isStringLengthBiggerThan(password, 5),
                        message: 'Min 6 symbols'
                    },
                    {
                        rule: $validations.isStringLengthSmallThan(password, 64),
                        message: 'Password must be small than 64 symbols'
                    }
                ]"
                ></FormInput>

                <button
                    @click="clickSetNewPassword()"
                    class="wv-btn--green wv-btn--block">
                    Save
                </button>

            </div>




    </div>
</template>

<script>
export default {
    name: 'RecoveryFinal',
    props: [],
    data() {
        return {
            password: '',
            isSavingNewPasswordFailed: false,
        }
    },
    computed: {
        isFormDataValid() {
            return this.$validations.isStringLengthBiggerThan(this.password, 5)
                && this.$validations.isStringLengthSmallThan(this.password, 64)
        },
        form() {
            return {
                password: this.password,
                email: this.$route.params.email,
                token: this.$route.params.token
            }
        },
    },
    methods: {
        clickSetNewPassword() {
            WV.Bus.emit('fireValidations')

            if (this.isFormDataValid) {
                this.$store.dispatch('auth/setNewPassword', this.form).then(() => {
                    WV.toast.success('Account password updated')
                    this.$router.push('/'+this.$route.params.lng+'/auth/login')
                }).catch(() => {
                    this.isSavingNewPasswordFailed = true
                })
            }
        }
    }
}
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .RecoveryFinal{
        max-width: 320px;
        &__title{
            font-family: $font-primary;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            letter-spacing: 0.005em;
            text-align: center;
            padding-bottom: 15px;
        }
        &__lead{
            text-align: center;
            font-family: $font-secondary;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.5px;
            a{
                color: $color-day-light-green;
            }
        }
        &__form{
            padding-top: 50px;
        }
    }
</style>