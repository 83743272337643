<template>
    <div class="Register">

        <div class="Register__title">{{ $lng.__('lk_webmaster_main', 'Start earning with us!')}}</div>

        <div class="Register__lead">
            {{ $lng.__('lk_webmaster_main', 'Do you have an account?')}}
            <router-link :to="`/${this.$route.params.lng}/auth/login${this.getSearchParams}`">{{ $lng.__('lk_webmaster_main', 'Log in')}}</router-link>
        </div>

        <Loading v-if="isLoading"></Loading>

        <div v-if="!isLoading" class="Register__form">

            <FormInput
                class="mb-20"
                :label="false"
                :placeholder="$lng.__('lk_webmaster_main', 'Username')"
                @keyup="trackYMGoal('loginsubmit')"
                v-model="form.username"
                :validations="[
                    {
                        rule: $validations.isStringLengthSmallThan(form.username, 64),
                        message: $lng.__('lk_webmaster_main', `Username must be small than 64 symbols`)
                    },
                    {
                        rule: $validations.isStringLengthBiggerThan(form.username, 2),
                        message: $lng.__('lk_webmaster_main', `Min 3 symbols`)
                    },
                ]"
            ></FormInput>

            <FormInput
                class="mb-20"
                :label="false"
                :placeholder="$lng.__('lk_webmaster_main', 'Email')"
                @keyup="trackYMGoal('emailsubmit')"
                v-model.trim="form.email"
                :validations="[
                    {
                        rule: $validations.isEmailValid(form.email),
                        message: $lng.__('lk_webmaster_main', `Verify email`)
                    },
                ]"
            ></FormInput>

            <FormSelect
                :label="false"
                class="mb-20"
                :showResetOption="false"
                :withIcons="true"
                :placeholder="$lng.__('lk_webmaster_main', 'Messenger')"
                v-model:selected="form.messenger[0].key"
                :options="messengerOptions"
            ></FormSelect>

            <FormInput
                v-if="form.messenger[0].key.length"
                class="mb-20"
                :label="false"
                @keyup="trackYMGoal('messangersubmit')"
                :placeholder="form.messenger[0].key + $lng.__('lk_webmaster_main', ' account')"
                v-model="form.messenger[0].value"
                :validations="[
                    {
                        rule: $validations.isStringNotEmpty(form.messenger[0].value),
                        message: $lng.__('lk_webmaster_main', `Enter messenger account`)
                    }
                ]"
            ></FormInput>

            <FormInput
                type="password"
                class="mb-20"
                :label="false"
                :placeholder="$lng.__('lk_webmaster_main', 'Password')"
                v-model="form.password"
                :validations="[
                    {
                        rule: $validations.isStringLengthBiggerThan(form.password, 5),
                        message: $lng.__('lk_webmaster_main', `Min 6 symbols`)
                    },
                    {
                        rule: $validations.isStringLengthSmallThan(form.password, 64),
                        message: $lng.__('lk_webmaster_main', `Password must be small than 64 symbols`)
                    }
                ]"
            ></FormInput>

            <div
                class="Register__agreement"
                @click="form.agreement=!form.agreement;isShowAgreementAlert=false">
                <FormRadio
                    :size="20"
                    :checked="form.agreement"
                ></FormRadio>
                {{ $lng.__('lk_webmaster_main', 'I have read and agree to the') }}
                <a
                    target="_blank"
                    class="Register__agreement__link"
                    :href="$config.baseUrl+'/terms/terms-en.pdf'">
                    {{ $lng.__('lk_webmaster_main', 'Terms of Service')}}
                </a>
            </div>

            <Alert
                v-if="isShowAgreementAlert"
                addclass="mb-20"
                :message="$lng.__('lk_webmaster_main', 'You have to agree with terms of service')"
            ></Alert>

            <Alert
                v-if="isRegistrationFailed"
                addclass="mb-20"
                :message="registrationFailedMessage"
            ></Alert>

            <button
                @click="clickRegister()"
                class="wv-btn--green wv-btn--block">
                {{ $lng.__('lk_webmaster_main', 'Register now')}}
            </button>

            <button
                @click.prevent="$router.push(`/${$route.params.lng}/auth/recovery${this.getSearchParams}`)"
                class="wv-btn--transparent wv-btn--block mt-20">
                {{ $lng.__('lk_webmaster_main', 'Restore password')}}
            </button>

        </div>

    </div>
</template>

<script>
export default {
    name: 'Register',
    props: [],
    data() {
        return {
            form: {
                username: '',
                email: '',
                messenger: [
                    {key: 'telegram', value: ''}
                ],
                ref: window.location.href,
                password: '',
                agreement: false,
                referrer: (WV.getUrlParamByName('referrer') !== null)
                    ? WV.getUrlParamByName('referrer')
                    : document.referrer,
                utm: {
                    utm_campaign: WV.getUrlParamByName('utm_campaign'),
                    utm_term: WV.getUrlParamByName('utm_term'),
                    utm_content: WV.getUrlParamByName('utm_content'),
                    utm_medium: WV.getUrlParamByName('utm_medium'),
                    utm_source: WV.getUrlParamByName('utm_source'),
                },
            },
            isShowAgreementAlert: false,
            isLoading: false,
            isRegistrationSuccess: false,
            isRegistrationFailed: false,
            registrationFailedMessage: '',
        };
    },
    computed: {
        isFormDataValid() {
            return this.$validations.isStringLengthBiggerThan(this.form.username, 2)
                && this.$validations.isStringLengthSmallThan(this.form.username, 64)
                && this.$validations.isEmailValid(this.form.email)
                && this.$validations.isStringNotEmpty(this.form.messenger[0].value)
                && this.$validations.isStringLengthBiggerThan(this.form.password, 5)
                && this.$validations.isStringLengthSmallThan(this.form.password, 64)
                && this.form.agreement;
        },
        messengerOptions() {
            return [
                {id: 'telegram', name: 'Telegram', icon: 'telegramLogoColor',},
                {id: 'skype', name: 'Skype', icon: 'skypeLogoColor',},
            ];
        },
        getSearchParams(){
            return WV.getUrlSearchParams();
        }
    },
    methods: {
        clickRegister() {
            WV.Bus.emit('fireValidations');
            if (!this.form.agreement) this.isShowAgreementAlert = true;
            if (this.isFormDataValid) this.startRegister();
            this.trackYMGoal('regsubmit')
        },
        startRegister() {
            this.isLoading = true;
            this.$store.dispatch('auth/register', this.form)
                .then(() => {
                    WV.toast.success('Account created');
                    this.loginAfterRegistration();
                    this.isLoading = false;
                })
                .catch(message => {
                    console.log(typeof message);
                    this.registrationFailedMessage = message;
                    this.isRegistrationFailed = true;
                    this.isLoading = false;
                });
            ym(46914753, 'reachGoal', 'user-registration');
            ym(46914753, 'reachGoal', 'newwebmaster');
        },
        loginAfterRegistration() {
            this.$store.commit('auth/setLoginAndPassword', {
                login: this.form.username,
                password: this.form.password,
            });
            this.$store.dispatch('auth/login')
                .then(() => {
                    WV.toast.success('Successfully authorized');
                    this.$router.push('/' + this.$route.params.lng + '/webmaster/poll');
                })
                .catch(() => {
                    this.isLoginFailed = true;
                });
        },
        trackYMGoal(name) {
            ym(46914753,'reachGoal', name);
        },

    },
    beforeMount() {
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.Register {
    width: 320px;
    &__title {
        font-family: $font-primary;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.005em;
        text-align: center;
        padding-bottom: 15px;
    }
    &__lead {
        text-align: center;
        font-family: $font-secondary;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        a {
            color: $color-day-light-green;
        }
    }
    &__form {
        padding-top: 50px;
    }
    &__agreement {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-secondary;
        font-size: 11px;
        margin-bottom: 20px;
        & > div:first-child {
            margin-right: 10px;
        }
        a {
            text-decoration: underline;
            padding-left: 5px;
        }
    }
}
</style>